<template>
  <div class="technical__modal">
    <div class="technical__modal__background">
        <div class="technical__modal__content" v-if="!isLoading">
            <div class="technical__modal__content__data">
                <div class="technical__modal__content__data__question">
                    <label 
                        for="technical-code" 
                        class="technical__modal__content__data__question__label"
                    >
                    Código del producto:
                </label>
                    <input 
                        class="technical__modal__content__data__question__input"
                        type="text" 
                        name="technical-code" 
                        id="technical-code"
                        placeholder="Código del producto"
                        v-model="technicalSheet.code"
                        >
                </div>
                

                <div class="technical__modal__content__data__question">
                    <label 
                        for="technical-brand"
                        class="technical__modal__content__data__question__label"
                        >
                        Marca del producto:
                    </label>
                    <select  
                        name="bad_day" 
                        class="technical__modal__content__data__question__opt"
                        v-model="technicalSheet.brand"
                        >
                        <option 
                            :value="brand.name"
                            v-for="brand in getAllBrands"
                            :key="brand.id"
                        >
                            {{brand.name}}
                        </option>
                    </select>        
                </div>
            
                <button 
                    class="technical__modal__content__data__create"
                    @click="createSheet"    
                >
                    Añadir Ficha Técnica
                </button>
            </div>
            <div class="technical__modal__content__thumbnail">
                <div 
                    class="technical__modal__content__thumbnail__container"
                    @click="chooseImage"
                >
                    
                    <img 
                    class="technical__modal__content__thumbnail__container__image"
                    :src="previewImage" 
                    alt=""
                >
                </div>
                <button 
                    class="technical__modal__content__thumbnail__button"
                    @click="chooseImage"
                >
                    Escoger imagen
                </button>
                <input 
                    type="file" 
                    name="technical-thumb" 
                    id="technical-thumb"
                    v-show="false"
                    ref="technicalThumb"
                    @change="onFileChange"
                >
            </div>
            <p  class="technical__modal__content__x" 
                @click="closeModal">
                x
            </p>
        </div>
        <div class="technical__modal__loader" v-else>
            Creando Ficha Técnica...
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('webStore', ['getAllBrands'])   
    },
    data() {
        return {
            technicalSheet: {
                brand: '',
                image: '',
                code: '',
            },
            previewImage: '',
            isLoading: false
        }
    },

    methods: {
        closeModal() {
            this.cleanTechnicalSheet()
            this.$emit('closeModal')
        },

        chooseImage() {
            this.$refs.technicalThumb.click()
        },

        onFileChange(event) {
            const file = event.target.files[0];
            this.technicalSheet.image = file
            const fr = new FileReader();
            (fr.onload = () => {
                this.previewImage = fr.result
            }),
            fr.readAsDataURL(file);
        },

        async createSheet() {
            try {
                if(!this.technicalSheet.brand || !this.technicalSheet.image || !this.technicalSheet.code) return
                this.isLoading = true
                await this.createTechnicalSheet(this.technicalSheet)
                this.$toast.success('Se agregó la Ficha Técnica')
                this.isLoading = false
                this.closeModal()
            } catch(e) {
                this.$toast.success('Ocurrió un error al crear la Ficha Técnica')
            }
        },

        cleanTechnicalSheet() {
            this.technicalSheet.brand = ''
            this.technicalSheet.code = ''
            this.technicalSheet.image = ''
            this.previewImage = ''
        },

        ...mapActions('vCard', ['createTechnicalSheet'])
    }
}
</script>

<style>

</style>
